import "core-js/modules/web.dom-collections.iterator.js";
export function Notifications() {
  return import(
  /* webpackChunkName: "features/notifications" */
  '../../notifications');
}
export function HomeTimeline() {
  return import(
  /* webpackChunkName: "features/home_timeline" */
  '../../home_timeline');
}
export function PublicTimeline() {
  return import(
  /* webpackChunkName: "features/public_timeline" */
  '../../public_timeline');
}
export function BubbleTimeline() {
  return import(
  /* webpackChunkName: "features/public_timeline" */
  '../../bubble_timeline');
}
export function RemoteTimeline() {
  return import(
  /* webpackChunkName: "features/remote_timeline" */
  '../../remote_timeline');
}
export function CommunityTimeline() {
  return import(
  /* webpackChunkName: "features/community_timeline" */
  '../../community_timeline');
}
export function HashtagTimeline() {
  return import(
  /* webpackChunkName: "features/hashtag_timeline" */
  '../../hashtag_timeline');
}
export function DirectTimeline() {
  return import(
  /* webpackChunkName: "features/direct_timeline" */
  '../../direct_timeline');
}
export function Conversations() {
  return import(
  /* webpackChunkName: "features/conversations" */
  '../../conversations');
}
export function ListTimeline() {
  return import(
  /* webpackChunkName: "features/list_timeline" */
  '../../list_timeline');
}
export function GroupTimeline() {
  return import(
  /* webpackChunkName: "features/groups/timeline" */
  '../../groups/timeline');
}
export function GroupMembers() {
  return import(
  /* webpackChunkName: "features/groups/timeline" */
  '../../groups/members');
}
export function GroupRemovedAccounts() {
  return import(
  /* webpackChunkName: "features/groups/timeline" */
  '../../groups/removed_accounts');
}
export function GroupCreate() {
  return import(
  /* webpackChunkName: "features/groups/timeline" */
  '../../groups/create');
}
export function GroupEdit() {
  return import(
  /* webpackChunkName: "features/groups/timeline" */
  '../../groups/edit');
}
export function Groups() {
  return import(
  /* webpackChunkName: "features/groups/index" */
  '../../groups/index');
}
export function Lists() {
  return import(
  /* webpackChunkName: "features/lists" */
  '../../lists');
}
export function Bookmarks() {
  return import(
  /* webpackChunkName: "features/bookmarks" */
  '../../bookmarks');
}
export function Status() {
  return import(
  /* webpackChunkName: "features/status" */
  '../../status');
}
export function PinnedStatuses() {
  return import(
  /* webpackChunkName: "features/pinned_statuses" */
  '../../pinned_statuses');
}
export function AccountTimeline() {
  return import(
  /* webpackChunkName: "features/account_timeline" */
  '../../account_timeline');
}
export function AccountGallery() {
  return import(
  /* webpackChunkName: "features/account_gallery" */
  '../../account_gallery');
}
export function Followers() {
  return import(
  /* webpackChunkName: "features/followers" */
  '../../followers');
}
export function Following() {
  return import(
  /* webpackChunkName: "features/following" */
  '../../following');
}
export function FollowRequests() {
  return import(
  /* webpackChunkName: "features/follow_requests" */
  '../../follow_requests');
}
export function GenericNotFound() {
  return import(
  /* webpackChunkName: "features/generic_not_found" */
  '../../generic_not_found');
}
export function FavouritedStatuses() {
  return import(
  /* webpackChunkName: "features/favourited_statuses" */
  '../../favourited_statuses');
}
export function Blocks() {
  return import(
  /* webpackChunkName: "features/blocks" */
  '../../blocks');
}
export function DomainBlocks() {
  return import(
  /* webpackChunkName: "features/domain_blocks" */
  '../../domain_blocks');
}
export function Mutes() {
  return import(
  /* webpackChunkName: "features/mutes" */
  '../../mutes');
}
export function MuteModal() {
  return import(
  /* webpackChunkName: "modals/mute_modal" */
  '../components/mute_modal');
}
export function Filters() {
  return import(
  /* webpackChunkName: "features/filters" */
  '../../filters');
}
export function ReportModal() {
  return import(
  /* webpackChunkName: "modals/report-modal/report-modal" */
  '../components/modals/report-modal/report-modal');
}
export function MediaGallery() {
  return import(
  /* webpackChunkName: "status/media_gallery" */
  '../../../components/media_gallery');
}
export function Video() {
  return import(
  /* webpackChunkName: "features/video" */
  '../../video');
}
export function Audio() {
  return import(
  /* webpackChunkName: "features/audio" */
  '../../audio');
}
export function MediaModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/media_modal');
}
export function VideoModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/video_modal');
}
export function BoostModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/boost_modal');
}
export function ConfirmationModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/confirmation_modal');
}
export function MissingDescriptionModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/missing_description_modal');
}
export function ActionsModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/actions_modal');
}
export function FocalPointModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/focal_point_modal');
}
export function HotkeysModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/hotkeys_modal');
}
export function ComposeModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/compose_modal');
}
export function ReplyMentionsModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/reply_mentions_modal');
}
export function UnauthorizedModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/unauthorized_modal');
}
export function EditFederationModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/edit_federation_modal');
}
export function EmbedModal() {
  return import(
  /* webpackChunkName: "modals/embed_modal" */
  '../components/embed_modal');
}
export function ComponentModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/component_modal');
}
export function ReblogsModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/reblogs_modal');
}
export function FavouritesModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/favourites_modal');
}
export function ReactionsModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/reactions_modal');
}
export function MentionsModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/mentions_modal');
}
export function LandingPageModal() {
  return import(
  /* webpackChunkName: "features/ui/modals/landing-page-modal" */
  '../components/modals/landing-page-modal');
}
export function BirthdaysModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/birthdays_modal');
}
export function BirthdayPanel() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../../../components/birthday-panel');
}
export function AccountNoteModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/account_note_modal');
}
export function ListEditor() {
  return import(
  /* webpackChunkName: "features/list_editor" */
  '../../list_editor');
}
export function ListAdder() {
  return import(
  /*webpackChunkName: "features/list_adder" */
  '../../list_adder');
}
export function Search() {
  return import(
  /*webpackChunkName: "features/search" */
  '../../search');
}
export function LoginPage() {
  return import(
  /* webpackChunkName: "features/auth_login" */
  '../../auth_login/components/login_page');
}
export function ExternalLogin() {
  return import(
  /* webpackChunkName: "features/external_login" */
  '../../external_login');
}
export function LogoutPage() {
  return import(
  /* webpackChunkName: "features/auth_login" */
  '../../auth_login/components/logout');
}
export function Settings() {
  return import(
  /* webpackChunkName: "features/settings" */
  '../../settings');
}
export function MediaDisplay() {
  return import(
  /* webpackChunkName: "features/settings" */
  '../../settings/media_display');
}
export function EditProfile() {
  return import(
  /* webpackChunkName: "features/edit_profile" */
  '../../edit_profile');
}
export function EditEmail() {
  return import(
  /* webpackChunkName: "features/edit_email" */
  '../../edit_email');
}
export function EmailConfirmation() {
  return import(
  /* webpackChunkName: "features/email_confirmation" */
  '../../email_confirmation');
}
export function EditPassword() {
  return import(
  /* webpackChunkName: "features/edit_password" */
  '../../edit_password');
}
export function DeleteAccount() {
  return import(
  /* webpackChunkName: "features/delete_account" */
  '../../delete_account');
}
export function SoapboxConfig() {
  return import(
  /* webpackChunkName: "features/soapbox_config" */
  '../../soapbox_config');
}
export function ExportData() {
  return import(
  /* webpackChunkName: "features/export_data" */
  '../../export_data');
}
export function ImportData() {
  return import(
  /* webpackChunkName: "features/import_data" */
  '../../import_data');
}
export function Backups() {
  return import(
  /* webpackChunkName: "features/backups" */
  '../../backups');
}
export function PasswordReset() {
  return import(
  /* webpackChunkName: "features/auth_login" */
  '../../auth_login/components/password_reset');
}
export function PasswordResetConfirm() {
  return import(
  /* webpackChunkName: "features/auth_login/password_reset_confirm" */
  '../../auth_login/components/password_reset_confirm');
}
export function MfaForm() {
  return import(
  /* webpackChunkName: "features/security/mfa_form" */
  '../../security/mfa_form');
}
export function ChatIndex() {
  return import(
  /* webpackChunkName: "features/chats" */
  '../../chats');
}
export function ChatRoom() {
  return import(
  /* webpackChunkName: "features/chats/chat_room" */
  '../../chats/chat-room');
}
export function ChatPanes() {
  return import(
  /* webpackChunkName: "features/chats/components/chat_panes" */
  '../../chats/components/chat-panes');
}
export function ServerInfo() {
  return import(
  /* webpackChunkName: "features/server_info" */
  '../../server_info');
}
export function Dashboard() {
  return import(
  /* webpackChunkName: "features/admin" */
  '../../admin');
}
export function ModerationLog() {
  return import(
  /* webpackChunkName: "features/admin/moderation_log" */
  '../../admin/moderation_log');
}
export function UserPanel() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/user_panel');
}
export function PromoPanel() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/promo_panel');
}
export function SignUpPanel() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/panels/sign-up-panel');
}
export function CtaBanner() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/cta-banner');
}
export function FundingPanel() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/funding_panel');
}
export function TrendsPanel() {
  return import(
  /* webpackChunkName: "features/trends" */
  '../components/trends-panel');
}
export function ProfileInfoPanel() {
  return import(
  /* webpackChunkName: "features/account_timeline" */
  '../components/profile_info_panel');
}
export function ProfileMediaPanel() {
  return import(
  /* webpackChunkName: "features/account_gallery" */
  '../components/profile_media_panel');
}
export function ProfileFields() {
  return import(
  /* webpackChunkName: "features/favourited_statuses" */
  '../../profile_fields');
}
export function ProfileFieldsPanel() {
  return import(
  /* webpackChunkName: "features/account_timeline" */
  '../components/profile_fields_panel');
}
export function PinnedAccountsPanel() {
  return import(
  /* webpackChunkName: "features/pinned_accounts" */
  '../components/pinned_accounts_panel');
}
export function InstanceInfoPanel() {
  return import(
  /* webpackChunkName: "features/remote_timeline" */
  '../components/instance_info_panel');
}
export function InstanceModerationPanel() {
  return import(
  /* webpackChunkName: "features/remote_timeline" */
  '../components/instance_moderation_panel');
}
export function LatestAccountsPanel() {
  return import(
  /* webpackChunkName: "features/admin" */
  '../../admin/components/latest_accounts_panel');
}
export function SidebarMenu() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../../../components/sidebar_menu');
}
export function UploadArea() {
  return import(
  /* webpackChunkName: "features/compose" */
  '../components/upload_area');
}
export function NotificationsContainer() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../containers/notifications_container');
}
export function ModalContainer() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../containers/modal_container');
}
export function ProfileHoverCard() {
  return import(
  /* webpackChunkName: "features/ui" */
  'soapbox/components/profile-hover-card');
}
export function StatusHoverCard() {
  return import(
  /* webpackChunkName: "features/ui" */
  'soapbox/components/status-hover-card');
}
export function CryptoDonate() {
  return import(
  /* webpackChunkName: "features/crypto_donate" */
  '../../crypto_donate');
}
export function CryptoDonatePanel() {
  return import(
  /* webpackChunkName: "features/crypto_donate" */
  '../../crypto_donate/components/crypto_donate_panel');
}
export function CryptoAddress() {
  return import(
  /* webpackChunkName: "features/crypto_donate" */
  '../../crypto_donate/components/crypto_address');
}
export function CryptoDonateModal() {
  return import(
  /* webpackChunkName: "features/crypto_donate" */
  '../components/crypto_donate_modal');
}
export function ScheduledStatuses() {
  return import(
  /* webpackChunkName: "features/scheduled_statuses" */
  '../../scheduled_statuses');
}
export function UserIndex() {
  return import(
  /* webpackChunkName: "features/admin/user_index" */
  '../../admin/user_index');
}
export function FederationRestrictions() {
  return import(
  /* webpackChunkName: "features/federation_restrictions" */
  '../../federation_restrictions');
}
export function Aliases() {
  return import(
  /* webpackChunkName: "features/aliases" */
  '../../aliases');
}
export function Migration() {
  return import(
  /* webpackChunkName: "features/migration" */
  '../../migration');
}
export function ScheduleForm() {
  return import(
  /* webpackChunkName: "features/compose" */
  '../../compose/components/schedule_form');
}
export function WhoToFollowPanel() {
  return import(
  /* webpackChunkName: "features/follow_recommendations" */
  '../components/who-to-follow-panel');
}
export function FollowRecommendations() {
  return import(
  /* webpackChunkName: "features/follow-recommendations" */
  '../../follow-recommendations');
}
export function Directory() {
  return import(
  /* webpackChunkName: "features/directory" */
  '../../directory');
}
export function RegisterInvite() {
  return import(
  /* webpackChunkName: "features/register_invite" */
  '../../register_invite');
}
export function Share() {
  return import(
  /* webpackChunkName: "features/share" */
  '../../share');
}
export function NewStatus() {
  return import(
  /* webpackChunkName: "features/new_status" */
  '../../new_status');
}
export function IntentionalError() {
  return import(
  /* webpackChunkName: "error" */
  '../../intentional_error');
}
export function Developers() {
  return import(
  /* webpackChunkName: "features/developers" */
  '../../developers');
}
export function CreateApp() {
  return import(
  /* webpackChunkName: "features/developers" */
  '../../developers/apps/create');
}
export function SettingsStore() {
  return import(
  /* webpackChunkName: "features/developers" */
  '../../developers/settings-store');
}
export function TestTimeline() {
  return import(
  /* webpackChunkName: "features/test_timeline" */
  '../../test_timeline');
}
export function DatePicker() {
  return import(
  /* webpackChunkName: "date_picker" */
  '../../birthdays/date_picker');
}
export function OnboardingWizard() {
  return import(
  /* webpackChunkName: "features/onboarding" */
  '../../onboarding/onboarding-wizard');
}
export function WaitlistPage() {
  return import(
  /* webpackChunkName: "features/verification" */
  '../../verification/waitlist_page');
}
export function CompareHistoryModal() {
  return import(
  /*webpackChunkName: "modals/compare_history_modal" */
  '../components/compare_history_modal');
}
export function AuthTokenList() {
  return import(
  /* webpackChunkName: "features/auth_token_list" */
  '../../auth_token_list');
}
export function VerifySmsModal() {
  return import(
  /* webpackChunkName: "features/ui" */
  '../components/modals/verify-sms-modal');
}
export function FamiliarFollowersModal() {
  return import(
  /*webpackChunkName: "modals/familiar_followers_modal" */
  '../components/familiar_followers_modal');
}
export function AnnouncementsPanel() {
  return import(
  /* webpackChunkName: "features/announcements" */
  '../../../components/announcements/announcements-panel');
}
export function FollowedHashtags() {
  return import('../../../features/followed_tags');
}