import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import { createAsyncThunk } from '@reduxjs/toolkit';
import get from 'lodash/get';
import KVStore from 'soapbox/storage/kv_store';
import { getAuthUserUrl } from 'soapbox/utils/auth';
import { parseVersion } from 'soapbox/utils/features';
import api from '../api';

const getMeUrl = state => {
  const me = state.me;
  return state.accounts.getIn([me, 'url']);
};
/** Figure out the appropriate instance to fetch depending on the state */


export const getHost = state => {
  const accountUrl = getMeUrl(state) || getAuthUserUrl(state);

  try {
    return new URL(accountUrl).host;
  } catch {
    return null;
  }
};
export const rememberInstance = createAsyncThunk('instance/remember', async host => {
  return await KVStore.getItemOrError("instance:".concat(host));
});
/** We may need to fetch nodeinfo on Pleroma < 2.1 */

const needsNodeinfo = instance => {
  const v = parseVersion(get(instance, 'version'));
  return v.software === 'Pleroma' && !get(instance, ['pleroma', 'metadata']);
};

export const fetchInstance = createAsyncThunk('instance/fetch', async (_arg, _ref) => {
  let {
    dispatch,
    getState,
    rejectWithValue
  } = _ref;

  try {
    const {
      data: instance
    } = await api(getState).get('/api/v1/instance');
    dispatch(fetchNodeinfo());
    return instance;
  } catch (e) {
    return rejectWithValue(e);
  }
});
/** Tries to remember the instance from browser storage before fetching it */

export const loadInstance = createAsyncThunk('instance/load', async (_arg, _ref2) => {
  let {
    dispatch,
    getState
  } = _ref2;
  const host = getHost(getState());
  await Promise.all([dispatch(rememberInstance(host || '')), dispatch(fetchInstance())]);
});
export const fetchNodeinfo = createAsyncThunk('nodeinfo/fetch', async (_arg, _ref3) => {
  let {
    getState
  } = _ref3;
  return await api(getState).get('/nodeinfo/2.1.json');
});